.App {
  width: 1278px;
  height: 718px;
  border: 0px solid #00f;
  position: absolute;
}

.corner-camera {
  z-index: 9;
  position: absolute;
  top: 15px;
  right: 15px;
  border: 0px solid #0f0;
  max-width: 240px;
  max-height: 180px;
  overflow: hidden;
}

.corner-camera video {
  margin: -30px 0 0 -40px;
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fullscreen-camera {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.fullscreen-camera video {
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
  object-fit: fill;
  width: 100%;
  height: 100%;
}